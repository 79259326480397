const STORE_KEY = {
    USER_TOKEN: 'user_token',
    LANGUAGE: 'language',
    SERVER_URL: ''
};

const storeData = {
    userToken: {
        get: () => localStorage.getItem(STORE_KEY.USER_TOKEN),
        set: (value) => localStorage.setItem(STORE_KEY.USER_TOKEN, value),
        remove: () => localStorage.removeItem(STORE_KEY.USER_TOKEN)
    },

    language: {
        get: () => localStorage.getItem(STORE_KEY.LANGUAGE),
        set: (value) => localStorage.setItem(STORE_KEY.LANGUAGE, value),
    },

    serverUrl: {
        get: () => localStorage.getItem(STORE_KEY.SERVER_URL),
        set: (value) => localStorage.setItem(STORE_KEY.SERVER_URL, value),
    }
};

export default storeData;