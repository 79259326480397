import React, {useState} from 'react';
import i18n from 'i18next';
import {Container, Row, Col, Button} from 'react-bootstrap';
import {ListUl} from 'react-bootstrap-icons';
import {useHookstate} from '@hookstate/core';
import {GPT_TYPES} from '../../../common/constants';
import Dropdown from '../../../components/Dropdown';
import globalState from '../../../state';

import './configBar.scss';

const ConfigBar = props => {
    const state = useHookstate(globalState);
    const [showConfigBar, setShowConfigBar] = useState(true);
    const [currOnlineService, setCurrOnlineService] = useState(GPT_TYPES.find(x => x.default) || GPT_TYPES[0]);

    const onClearBtnClick = e => {
        state.msgList.set([]);
    }

    const onSelectOnlineService = (data) => {
        setCurrOnlineService(data)
        state.gptModelType.set(data.value)
    }

    return <Container className="config-bar" fluid>
        <Row>
            <Col xs="auto" className="text-end">
                <Button className="icon-btn" onClick={() => setShowConfigBar(!showConfigBar)}>
                    <ListUl />
                </Button>
            </Col>
            <Col className="wrapper" style={{display: showConfigBar ? null : 'none'}}>
                <Button className="btn btn-link" onClick={onClearBtnClick}>{i18n.t('clear')}</Button>
                <Dropdown
                    label={currOnlineService.label}
                    showArrow={true}
                    onSelect={onSelectOnlineService}
                    options={GPT_TYPES}
                />
            </Col>
        </Row>
    </Container>
}

export default ConfigBar;
