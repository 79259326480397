export const MESSAGE_STATUS = {
    SUCCESS: 'success',
    FAILED: 'failed',
};

export const MESSAGE_TYPE = {
    BOT: 'bot',
    USER: 'user'
};

export const GPT_TYPES = [
    {
        value: 'cz',
        label: 'Online Service 1',
        default: true
    },
    {
        value: 'op',
        label: 'Online Service 2'
    }
];

export const RECORDING_STATUS = {
    UNDEFINED: 'undefined',
    CLOSING: 'closing',
    CLOSED: 'closed',
    CONNECTING: 'connecting',
    OPEN: 'open'
}

export const TTS_PROVIDER = {
    BAIDU: 'baidu',
    XUNFEI: 'xunfei'
}