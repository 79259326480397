import React, {useEffect, useState, useRef} from 'react';
import {Image, Container, Col, Row} from 'react-bootstrap';
import classNames from 'classnames';
import moment from 'moment';
import {ExclamationCircleFill, PauseFill, VolumeUpFill} from 'react-bootstrap-icons';
import storeData from '../../../common/storageData';
import {MESSAGE_STATUS, MESSAGE_TYPE} from '../../../common/constants';
import {replaceUrlToDomLink} from '../../../utils/tools';
import AudioFile from '../audioFile';

import profileDefault from '../../../assets/images/profile_default.png';
import profileGpt from '../../../assets/images/profile_gpt.png';
import './ChatLine.scss';

const ChatLine = props => {
    const {
        contents = '', 
        type = MESSAGE_TYPE.USER, 
        status = MESSAGE_STATUS.SUCCESS, 
        hasAutoPlayed,
        createdAt
    } = props.msg;

    const { 
        isLast, 
        enableAutoplay,
        getAnswerCompleted,
        updateLastMsgAutoPlayingStatus 
    } = props;

    const [isLoading, setIsLoading] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const [hasGotAudio, setHasGotAudio] = useState(false)
    const refAudio = useRef(null)

    let profilePic, content, formatTime;
    let isEnglish = storeData.language.get() !== 'zh-CN';

    const onClickAudioPlay = () => {
        if(isLoading) return;

        setIsPlaying(!isPlaying);

        if(isPlaying) {
            refAudio.current.pause();
            return;
        }

        if(hasGotAudio) {
            refAudio.current.play();
            return;
        }

        audioPlaying();
    }

    const audioPlaying = () => {
        setIsLoading(true)
        const audioFile = new AudioFile();
        audioFile.getUrl(contents, (url, error) => {
            setIsLoading(false)
            if(error) {
                setIsPlaying(false);
                return;
            }

            setHasGotAudio(true)
            const audioPlayer = refAudio.current;
            audioPlayer.src = url;
            audioPlayer.play();
        })
    }

    if(createdAt) {
        formatTime = isEnglish 
            ? moment(createdAt).format('lll') 
            : moment(createdAt).format('YYYY年MM月DD日 hh:mm:ss');
    }

    switch (type) {
        case MESSAGE_TYPE.BOT:
            profilePic = profileGpt;
            break;
        case MESSAGE_TYPE.USER:
            profilePic = profileDefault;
            break;
        default:
            break;
    }

    switch (status) {
        case MESSAGE_STATUS.SUCCESS:
            content = <>
                <div className="text">
                    <span dangerouslySetInnerHTML={{__html: replaceUrlToDomLink(contents)}} />
                    {
                        type === MESSAGE_TYPE.BOT &&
                        <span 
                            className="audio"
                            onClick={onClickAudioPlay}
                        >
                            {
                                isLoading 
                                    ? <div className='loading'></div>
                                    : (isPlaying ? <PauseFill color='#838c9e'/> : <VolumeUpFill color='#838c9e'/>)
                            }
                        </span>
                    }
                </div>
                <span className="time">{formatTime}</span>
            </>;
            break;
        case MESSAGE_STATUS.FAILED:
            content = <>
                <div className="text">
                    <ExclamationCircleFill className="icon" color="red" />
                    {contents}
                </div>
            </>
            break;
        default: 
            content = <div className="text">{contents}</div>;
            break;
    }

    useEffect(() => {
        refAudio.current.addEventListener('ended', () => {  
            setIsPlaying(false)
        });

        // auto playing
        if(contents.length < 300) {
            if(enableAutoplay && isLast && type === MESSAGE_TYPE.BOT && getAnswerCompleted && !hasAutoPlayed) {
                audioPlaying()
                updateLastMsgAutoPlayingStatus(true)
            }
        }
    }, [isLast, type, hasAutoPlayed, getAnswerCompleted])

    return <Container fluid>
        <audio ref={refAudio}>Audio not supported!</audio>
        <Row className={classNames('chat-line', {
            'user': type === MESSAGE_TYPE.USER,
            'bot': type === MESSAGE_TYPE.BOT,
            'failed': status === MESSAGE_STATUS.FAILED
        })}>
            <Col xs="auto" className="profilepic">
                <Image rounded src={profilePic}/>
            </Col>
            <Col xs="auto" className="content">
                {content}
            </Col>
        </Row>
    </Container>;
}

export default ChatLine;
