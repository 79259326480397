import axios from 'axios';
import * as tokenApi from '../api/token';

const BAIDU_TTS_ACCESS_TOKEN_CACHE_TIME = 10*24*3600*1000;

class BaiduTTS {
    constructor(text, callback) {
        this.ttsURL = 'https://tsn.baidu.com/text2audio'
        this.text = text;
        this.callback = callback;
    }
    async start() {
        let accessToken = await this.getAccessToken();
        if(!accessToken) return;

        let params = {
            tex: encodeURIComponent(this.text),
            tok: accessToken,
            cuid: 'roma-speaker',
            ctp: '1',
            lan: 'zh',
            spd: 6,
            vol: 9, // 0-9
            per: 0, // 度小美=0, 度小宇=1, 度逍遥=3, 度丫丫=4
            aue: 3 // {3: "mp3", 4: "pcm", 5: "pcm", 6: "wav"}
        };

        this.sendData(params);
    }
    async getAccessToken() {
        let now = Date.now();
        let accessToken = localStorage.getItem('bd_token');
        if(accessToken && now - localStorage.getItem('bd_token_time') < BAIDU_TTS_ACCESS_TOKEN_CACHE_TIME) {
            return accessToken;
        }

        let response = await tokenApi.getBaiduTTSAccessToken();
        if(response.error) {
            console.log('get bd token error:', response.error)
            this.callback({
                chunk: null,
                error: 'An error occurred on the server. Please try again'
            })
            return;
        }

        accessToken = response.data.accessToken;

        localStorage.setItem('bd_token', accessToken);
        localStorage.setItem('bd_token_time', now);

        return accessToken
    }
    async sendData(params) {
        let response = await axios.post(this.ttsURL, params, {
            responseType: 'arraybuffer',
            'headers': {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': '*/*'
            }
        })

        let data = response.data;

        if(data.err_no) {
            console.error('bd tts response error: ' + JSON.stringify(data))
            this.callback({
                chunk: null,
                error: 'An error occurred on the server. Please try again'
            })
            return
        }

        this.callback({
            chunk: data,
            isLastData: true,
            error: null
        });
    }
}

export default BaiduTTS
