import React, {useRef, useEffect} from 'react';
import {useHookstate} from '@hookstate/core';
import globalState from '../../state';
import ResponseWindow from './ResponseWindow';
import InputBox from './InputBox';
import ConfigBar from './ConfigBar';
import './style.scss';

const Index = props => {
    const state = useHookstate(globalState);
    const chatViewRef = useRef(null);
    const msgList = state.msgList.get();

    useEffect(() => {
        setTimeout(() => {
            scrollToBottom(chatViewRef.current); 
        }, 100)
    }, [msgList]);

    const scrollToBottom = (elm) => {
        const scrollHeight = elm.scrollHeight;
        const height = elm.clientHeight;
        const maxScrollTop = scrollHeight - height;
        elm.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    return <div className="chat-view" ref={chatViewRef}>
        <ResponseWindow />
        <div className="footer">
            <div className="footer-wrapper">
                <ConfigBar />
                <InputBox />
            </div>
        </div>
    </div>;
}

export default Index;
