import React, {useState} from 'react';
import i18n from 'i18next';
import classNames from 'classnames';
import {NavLink} from 'react-router-dom';
import { Image } from 'react-bootstrap';
import {ChatDots, ClockHistory, Gear, ChevronDoubleLeft, ChevronDoubleRight} from 'react-bootstrap-icons';
import Tooltip from '../Tooltip';
import logoImg from '../../assets/images/logo-icon.png';

import './style.scss';

const Sidebar = props => {
    const [isCollapse, setIsCollapse] = useState(true);

    const navList = [
        {
            icon: <ChatDots />,
            tooltip: i18n.t('chat'),
            path: '/chat',
            isCollapseSidebar: true
        },
        {
            icon: <ClockHistory />,
            tooltip: i18n.t('msg_history'),
            path: '/history',
            isCollapseSidebar: false
        },
        {
            icon: <Gear />,
            tooltip: i18n.t('setting'),
            path: '/setting',
            isCollapseSidebar: true
        },
    ];

    const onToggleSidebar = () => {
        setIsCollapse(!isCollapse);
    }

    return <div className={classNames("sidebar", {"sidebar-collapse": isCollapse})}>
        <div className="sidebar-action">
            <div className="logo">
                <Image src={logoImg} />
            </div>

            {
                navList.map((nav, idx) => {
                    return <Tooltip key={idx} title={nav.tooltip}>
                        <NavLink key={idx} className="link" to={nav.path}
                            onClick={() => setIsCollapse(nav.isCollapseSidebar)}
                        >
                            {nav.icon}
                        </NavLink>
                    </Tooltip>
                })
            }

            <div className="toggle-sidebar">
                <Tooltip title={isCollapse ? i18n.t('expand_sidebar') : i18n.t('collapse_sidebar')} side="top">
                    <button onClick={onToggleSidebar}>
                        {isCollapse ? <ChevronDoubleRight /> : <ChevronDoubleLeft />}
                    </button>
                </Tooltip>
            </div>
        </div>
        <div className="sidebar-content">
            {props.content && <props.content />}
        </div>
    </div>
}

export default Sidebar;