import { useEffect, useMemo } from 'react';
import {Route, Routes} from 'react-router-dom';
import i18n from 'i18next';
import {useHookstate} from '@hookstate/core';
import globalState from './state';
import routes from './routes/router';
import Theme from './components/Theme';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

function App() {
    const state = useHookstate(globalState);
    const lang = state.appLang.get();

    useMemo(() => {
        i18n.changeLanguage(lang);
    }, [lang]);

    useEffect(() => {
        document.addEventListener('contextmenu', function(event) {
            event.preventDefault();
        });
    }, [])

    return (
        <div className="App">
            <Routes>
                {
                    routes.map((route, idx) => {
                        let pageComponent = <Theme
                            header={route.showHeader && Header}
                            sidebar={route.showSidebar && Sidebar}
                            sidebarContent={route.sidebar}
                        >
                            <route.pageComponent pageName={route.name} />
                        </Theme>;

                        return <Route
                            key={idx}
                            path={route.path}
                            element={pageComponent}
                        />
                    })
                }
            </Routes>
            <ToastContainer 
                position="top-center"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeButton={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default App;
