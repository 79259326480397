import http from'./http';

export async function getBaiduTTSAccessToken() {
    try {
        const result = await http.get('/api/token/bd');
        return result.data
    } catch (e) {
        return {
            error: e,
        }
    }
}
