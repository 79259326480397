import React, {useEffect} from 'react';
import i18n from 'i18next';
import {Image, Container, Col, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';
import {useHookstate} from '@hookstate/core';
import {PersonCircle, PersonFill, BoxArrowLeft, VolumeMuteFill, VolumeUpFill} from 'react-bootstrap-icons';
import globalState from '../../state';
import storeData from '../../common/storageData';
import Dropdown from '../Dropdown';
import Img from '../../assets/images/logo-text.svg'
import './style.scss';

const Index = props => {
    const navigate = useNavigate();
    const state = useHookstate(globalState);
    const enableAutoplay = state.enableAutoplay.get()

    const dropdownOptions = [
        {
            label: i18n.t('my_profile'),
            value: 'profile',
            icon: <PersonFill/>
        },
        {
            label: i18n.t('sign_out'),
            value: 'logout',
            icon: <BoxArrowLeft/>
        }
    ];

    const handleSelected = data => {
        switch(data.value) {
            case 'profile':
                navigate('/profile');
                break;
            case 'logout':
                handleLogout();
                break;
            default:
                break;
        }
    }

    const handleAutoplayClick = () => {
        state.enableAutoplay.set(!state.enableAutoplay.get());
    }

    const handleLogout = () => {
        storeData.userToken.remove();
        navigate('/login');
    }

    return <Container fluid className="app-header">
        <Row >
            <Col xs="auto">
                {/* <Dropdown
                    icon={<div className="profile"><PersonCircle /></div>}
                    options={dropdownOptions}
                    onSelect={data => handleSelected(data)}
                /> */}
                <Image className='logo' src={Img} />
            </Col>
            <Col className="title long">DC Service Assistant</Col>
            <Col className="title short">Assistant</Col>
            <Col xs="auto" className="right">
                <div className="autoplay" onClick={handleAutoplayClick}>
                    {enableAutoplay ? <VolumeUpFill className="icon"/> : <VolumeMuteFill className="icon"/>}
                    <p>{i18n.t('autoplay')}</p>
                </div>
            </Col>
        </Row>
    </Container>;
}

export default Index;
