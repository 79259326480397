import React from 'react';
import './MsgLoading.scss';

const MsgLoading = (props) => {
    return <div className="msg-loading">
        <div></div>
        <div></div>
        <div></div>
    </div>;
}

export default MsgLoading;