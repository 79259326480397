import axios from 'axios';
import {toast} from 'react-toastify';
import config from '../configs'

const _axios = axios.create({
    baseURL: config.apiServerUrl,
    timeout: 1 * 60 * 1000
});

_axios.interceptors.request.use(config => {
    return config;
}, error => {
    return Promise.reject(error);
});

_axios.interceptors.response.use(response => { 
    return response;
}, error => {
    if(!error.response) {
        error._message = error.message;
        return Promise.reject(error);
    }

    const data = error.response.data || {};
    error._message = data.error || error.message;

    toast(error._message);

    return Promise.reject(error);
});

export default _axios;