import TTS from '../../common/tts'
import config from '../../configs';
import {TTS_PROVIDER} from '../../common/constants'

class GetAudioFile {
    _audioDataArrays = [];

    _mergeUint8Arrays = (arrays) => {
        let totalLength = 0;
        arrays.forEach(arr => {
            totalLength += arr.length;
        });

        const mergedArray = new Uint8Array(totalLength);
        let offset = 0;
        arrays.forEach(arr => {
            mergedArray.set(arr, offset);
            offset += arr.length;
        });

        return mergedArray;
    }

    getUrl = (text, callback) => {
        let url;
        TTS.start(text, ({chunk, error, isLastData}) => {
            if(error) {
                callback(null, error)
                return;
            }

            try {
                if(config.tts_provider === TTS_PROVIDER.XUNFEI) {
                    this._audioDataArrays.push(chunk);
                    if (isLastData) {
                        const mergedAudioData = this._mergeUint8Arrays(this._audioDataArrays);
                        url = this._generateUrl(mergedAudioData)
                        callback(url)
                    }
                }
                else if(config.tts_provider === TTS_PROVIDER.BAIDU) {
                    url = this._generateUrl(chunk)
                    callback(url)
                }
            } catch (error) {
                console.error('Error processing audio data:', error);
                callback(null, error)
            }
        })
    }

    _generateUrl = (audioData) => {
        const blob = new Blob([audioData], { type: 'audio/mp3' });
        return URL.createObjectURL(blob);
    }
}

export default GetAudioFile;