import CryptoJS from 'crypto-js';
import * as tools from '../utils/tools'
import {RECORDING_STATUS} from './constants';
import config from '../configs';

class STT {
        perTalkMaxDuration = 30; //second

    constructor() {
        this.notSupported = false;

        this.iatWS = null;
        this.resultText = "";
        this.resultTextTemp = "";
        this.countdownInterval = null;
        this.recordingStatus = RECORDING_STATUS.UNDEFINED;

        this._init();
    }

    async _init() {
        this.recorder = new window.RecorderManager("./libs/xf.dist/");

        this.recorder.onStart = () => {
            this.changeRecordingStatus("OPEN");
        };

        this.recorder.onFrameRecorded = ({ isLastFrame, frameBuffer }) => {
            if (this.iatWS.readyState === this.iatWS.OPEN) {
                this.iatWS.send(
                    JSON.stringify({
                        data: {
                            status: isLastFrame ? 2 : 1,
                            format: "audio/L16;rate=16000",
                            encoding: "raw",
                            audio: this.toBase64(frameBuffer),
                        },
                    })
                );
                if (isLastFrame) {
                    this.changeRecordingStatus(RECORDING_STATUS.CLOSING);
                }
            }
        };
        this.recorder.onStop = () => {
            clearInterval(this.countdownInterval);
        };
    }

    /**
     * @param {Function} callback({
     *      msg: string,
     *      status: RECORDING_STATUS, (UNDEFINED | CLOSING | CLOSED | CONNECTING | OPEN)
     * })
     */
    async start(callback) {
        this.callback = callback;
        if (this.recordingStatus === RECORDING_STATUS.UNDEFINED || 
            this.recordingStatus === RECORDING_STATUS.CLOSED) {
            this.connectWebSocket();
        } else if (
            this.recordingStatus === RECORDING_STATUS.CONNECTING || 
            this.recordingStatus === RECORDING_STATUS.OPEN) {
            this.recorder.stop();
        }
    }

    stop() {
        this.recorder.stop();
    }

    getWebSocketUrl() {
        let url = "wss://iat-api.xfyun.cn/v2/iat";
        let host = "iat-api.xfyun.cn";
        let apiKey = tools.decode(config.xf_API_KEY);
        let apiSecret = tools.decode(config.xf_API_SECRET);
        let date = new Date().toGMTString();
        let algorithm = "hmac-sha256";
        let headers = "host date request-line";
        let signatureOrigin = `host: ${host}\ndate: ${date}\nGET /v2/iat HTTP/1.1`;
        let signatureSha = CryptoJS.HmacSHA256(signatureOrigin, apiSecret);
        let signature = CryptoJS.enc.Base64.stringify(signatureSha);
        let authorizationOrigin = `api_key="${apiKey}", algorithm="${algorithm}", headers="${headers}", signature="${signature}"`;
        let authorization = btoa(authorizationOrigin);
        url = `${url}?authorization=${authorization}&date=${date}&host=${host}`;
        return url;
    }

    toBase64(buffer) {
        let binary = "";
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    countdown() {
        let seconds = this.perTalkMaxDuration;
        this.countdownInterval = setInterval(() => {
            seconds = seconds - 1;
            if (seconds <= 0) {
                clearInterval(this.countdownInterval);
                this.recorder.stop();
            }
        }, 1000);
    }

    changeRecordingStatus(status) {
        this.callback({
            status: status
        });
        this.recordingStatus = status;
        if (status === RECORDING_STATUS.CONNECTING) {
            this.resultText = "";
            this.resultTextTemp = "";
        } else if (status === RECORDING_STATUS.OPEN) {
            this.countdown();
        }
    }

    renderResult(resultData) {
        let jsonData = JSON.parse(resultData);
        if (jsonData.data && jsonData.data.result) {
            let data = jsonData.data.result;
            let str = "";
            let ws = data.ws;
            for (let i = 0; i < ws.length; i++) {
                str = str + ws[i].cw[0].w;
            }
            if (data.pgs) {
                if (data.pgs === "apd") {
                    this.resultText = this.resultTextTemp;
                }
                this.resultTextTemp = this.resultText + str;
            } else {
                this.resultText += str;
            }

            this.callback({
                msg: this.resultTextTemp || this.resultText || "",
                status: this.recordingStatus
            })
        }
        if (jsonData.code === 0 && jsonData.data.status === 2) {
            this.iatWS.close();
        }
        if (jsonData.code !== 0) {
            this.iatWS.close();
            console.error(jsonData);
        }
    }

    connectWebSocket() {
        const websocketUrl = this.getWebSocketUrl();
        if ("WebSocket" in window) {
            this.iatWS = new WebSocket(websocketUrl);
        } else if ("MozWebSocket" in window) {
            this.iatWS = new window.MozWebSocket(websocketUrl);
        } else {
            alert("浏览器不支持WebSocket");
            return;
        }
        this.changeRecordingStatus(RECORDING_STATUS.CONNECTING);
        this.iatWS.onopen = (e) => {
            this.recorder.start({
                sampleRate: 16000,
                frameSize: 1280,
            });
            let params = {
                common: {
                    app_id: tools.decode(config.xf_APPID),
                },
                business: {
                    language: "zh_cn",
                    domain: "iat",
                    accent: "mandarin",
                    vad_eos: 5000,
                    dwa: "wpgs",
                },
                data: {
                    status: 0,
                    format: "audio/L16;rate=16000",
                    encoding: "raw",
                },
            };
            this.iatWS.send(JSON.stringify(params));
        };
        this.iatWS.onmessage = (e) => {
            this.renderResult(e.data);
        };
        this.iatWS.onerror = (e) => {
            console.error(e);
            this.recorder.stop();
            this.changeRecordingStatus(RECORDING_STATUS.CLOSED);
        };
        this.iatWS.onclose = (e) => {
            this.recorder.stop();
            this.changeRecordingStatus(RECORDING_STATUS.CLOSED);
        };
    }
}

export default new STT();
