// import Login from '../pages/login';
import Chat from '../pages/chat';
// import Profile from '../pages/profile';
// import Setting from '../pages/setting';
// import HistorySidebar from '../pages/history/sidebar';

const routesConfig = [
    // {
    //     name: 'login-page',
    //     path: '/login',
    //     protected: false,
    //     showHeader: false,
    //     showSidebar: false,
    //     pageComponent: Login,
    // },
    {
        name: 'chat-page',
        path: '/',
        protected: true,
        showHeader: true,
        showSidebar: false,
        pageComponent: Chat
    },
    // {
    //     name: 'history-page',
    //     path: '/history',
    //     protected: true,
    //     showHeader: true,
    //     showSidebar: false,
    //     pageComponent: Chat,
    //     sidebar: HistorySidebar
    // },
    // {
    //     name: 'profile-page',
    //     path: '/profile',
    //     protected: true,
    //     showHeader: true,
    //     showSidebar: false,
    //     pageComponent: Profile
    // },
    // {
    //     name: 'setting-page',
    //     path: '/setting',
    //     protected: true,
    //     showHeader: true,
    //     showSidebar: false,
    //     pageComponent: Setting
    // }
];

export default routesConfig;
