import i18n from 'i18next';
import locales from "./locales.json";

let enLocale = {};
let zhCNLocale = {};

for(let key in locales) {
    enLocale[key] = locales[key]['en'];
    zhCNLocale[key] = locales[key]['zh-CN'];
}

const resources = {
    'en': {
        translation: enLocale
    },
    'zh-CN': {
        translation: zhCNLocale
    }
};

i18n.init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    keySeparator: '.',
    nsSeparator: false,
    interpolation: {
    escapeValue: false
    }
}).catch(e => console.log(e));

export default i18n;