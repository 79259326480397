import React, {useEffect, useState} from 'react';
import {Stack} from "react-bootstrap";
import {useHookstate} from '@hookstate/core';
import globalState from '../../../state';
import ChatLine from './chatLine';
import MsgLoading from './MsgLoading';
import './index.scss';

const ResponseWindow = props => {
    const state = useHookstate(globalState);
    const gptLoading = state.gptLoading.get();
    const enableAutoplay = state.enableAutoplay.get();
    const getAnswerCompleted = state.getAnswerCompleted.get();
    const [contentList, setContentList] = useState(<></>);

    const updateLastMsgAutoPlayingStatus = (isPlayed) => {
        const oldMsgList = state.msgList.get({noproxy: true, stealth: true});
        const lastMsg = oldMsgList.pop();
        lastMsg.hasAutoPlayed = isPlayed;

        state.msgList.set([
            ...oldMsgList,
            lastMsg
        ]);
    }

    useEffect(() => {
        const msgList = state.msgList.get();
        setContentList(msgList.map((item, idx) => 
            <ChatLine 
                key={idx} 
                msg={item} 
                isLast={idx === msgList.length-1} 
                enableAutoplay={enableAutoplay}
                getAnswerCompleted={getAnswerCompleted}
                updateLastMsgAutoPlayingStatus={updateLastMsgAutoPlayingStatus} 
            />
        ));
    }, [state.msgList, getAnswerCompleted]);

    return <div className="response-window">
        <Stack gap={1}>
            {
                contentList
            }
        </Stack>
        {
            gptLoading && 
            <div className="loading-wrap"><MsgLoading /></div>
        }
    </div>;
}

export default ResponseWindow;
