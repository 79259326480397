import http from'./http';

export async function sendChat({clientId, question, gptModel}) {
    try {
        const result = await http.post('/api/chat', {
            clientId,
            question,
            model: gptModel
        });
        return result.data
    } catch (e) {
        return {
            error: e,
        }
    }
}
