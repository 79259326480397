import React from 'react';
import PropTypes from 'prop-types';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import './style.scss';

const TooltipWrapper = (props = {title: '', side: 'right'}) => {
    if(!props.title) {
        return props.children;
    }

    return <OverlayTrigger
        placement={props.side}
        overlay={
        <Tooltip>
            {props.title}
        </Tooltip>
        }
    >
        {props.children}
    </OverlayTrigger>
}
TooltipWrapper.propTypes = {
    title: PropTypes.string,
    side: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    children: PropTypes.element
}

export default TooltipWrapper;