import {hookstate} from '@hookstate/core';
import {GPT_TYPES} from '../common/constants';
import storeData from '../common/storageData';

const globalState = hookstate({
    appLang: storeData.language.get() || 'en',
    userInfo: {},
    history: [],
    msgList: [
        {
            type: 'bot',
            contents: `Hi! Welcome to the DeepComputing Service Center. 🎉

I'm DeepComputing’s AI customer service representative. How can I assist you with any questions about our company's products? 📦`,
            status: 'success',
            createdAt: Date.now()
        }
    ],
    preinputQuestion: '',
    enableAutoplay: false,
    getAnswerCompleted: false,
    gptLoading: false,
    gptModelType: (GPT_TYPES.find(x => x.default) || GPT_TYPES[0]).value
});

export default globalState;
