import React, {useState, useRef, useEffect} from 'react';
import i18n from 'i18next';
import classNames from 'classnames';
import {Button} from 'react-bootstrap';
import {MicFill, MicMute, Mic, SendFill} from 'react-bootstrap-icons';
import {useHookstate} from '@hookstate/core';
import {RECORDING_STATUS} from '../../../common/constants';
import {sendChat} from '../../../api/chat';
import {generateMachineCode} from '../../../utils/tools';
import {MESSAGE_STATUS, MESSAGE_TYPE} from '../../../common/constants';
import globalState from '../../../state';
import stt from '../../../common/stt';
import './inputBox.scss';

const InputBox = props => {
    const minLineHeight = 60;
    const maxLineHeight = 80;
    const state = useHookstate(globalState);
    const [isMicPressed, setIsMicPressed] = useState(false);
    const preinputQuestion = state.preinputQuestion.get();
    const gptLoading = state.gptLoading.get();
    const refInput = useRef(null);

    useEffect(() => {
        refInput.current.value = preinputQuestion;
        resizeTextarea();
    }, [preinputQuestion]);

    const resizeTextarea = () => {
        const inputEl = refInput.current;
        inputEl.style.height = `${minLineHeight}px`;

        if(inputEl.scrollHeight > maxLineHeight) {
            inputEl.style.overflowY = 'auto';
            inputEl.style.height = `${maxLineHeight}px`;

        } else if(inputEl.scrollHeight > minLineHeight) {
            inputEl.style.overflowY = 'hidden';
            inputEl.style.height = `${inputEl.scrollHeight}px`;
        }
    }

    const onMicBtnClick = event => {
        let currentMsg = refInput.current.value;
        let isListening = !isMicPressed;
        setIsMicPressed(isListening);
        if(isListening) {
            stt.start(({msg, status}) => {
                if(msg) {
                    refInput.current.value = currentMsg + msg;
                }
                if(status === RECORDING_STATUS.CLOSING || status === RECORDING_STATUS.CLOSED) {
                    setIsMicPressed(false);
                }
            });
        } else {
            stt.stop();
        }
    }

    const onInputChange = event => {
        resizeTextarea();

        const contents = refInput.current.value.trim();
        if(!contents && preinputQuestion) {
            state.preinputQuestion.set('');
        }
    }
    const onKeyDown = event => {
        if (event.key === 'Enter') {
            if (event.metaKey || event.ctrlKey) {
                document.execCommand('insertText', false, '\n');
            } else {
                event.preventDefault();
                onClickSendButton();
            }
        }
    }
    const onClickSendButton = async event => {
        const question = refInput.current.value.trim();
        if(!question) return;

        state.getAnswerCompleted.set(false)

        const gptModel = state.gptModelType.get();
        const clientId = generateMachineCode();

        state.msgList.merge([
            {
                type: MESSAGE_TYPE.USER,
                contents: question,
                status: MESSAGE_STATUS.SUCCESS,
                createdAt: Date.now()
            }
        ]);

        refInput.current.value = "";
        if(preinputQuestion) {
            state.preinputQuestion.set('');
        }

        state.gptLoading.set(true)

        sendChat({clientId, question, gptModel}).then(apiData => {
            state.gptLoading.set(false)

            let oldHistory = state.history.get({noproxy: true, stealth: true});
            let oldMsgList = state.msgList.get({noproxy: true, stealth: true});

            if(apiData.error || apiData.code !== 0) {
                state.msgList.set([
                    ...oldMsgList,
                    {
                        type: MESSAGE_TYPE.BOT,
                        contents: `ERROR: ${apiData.error ? apiData.error.toString() : apiData.msg}`,
                        status: MESSAGE_STATUS.FAILED,
                        createdAt: Date.now()
                    }]
                );
                return;
            }

            // get answer successful
            let data = apiData.data || {};
            let answer = data.content;
            state.getAnswerCompleted.set(true)

            state.msgList.set([
                ...oldMsgList,
                {
                    type: MESSAGE_TYPE.BOT,
                    contents: answer,
                    status: MESSAGE_STATUS.SUCCESS,
                    createdAt: Date.now()
                }
            ]);

            // add to history list
            state.history.set([
                ...oldHistory,
                {
                    question: question,
                    answer: answer,
                    createdAt: Date.now()
                }
            ])
        })
    }

    return <div className="input-box">
        <div className="mic-wrapper">
            <div className={classNames("mic-wave", {active: isMicPressed})}></div>
            <div className="mic-bg"></div>
            <Button 
                className="mic-btn icon-btn"
                onClick={onMicBtnClick}
                disabled={stt.notSupported || gptLoading}
            >
                {stt.notSupported ? <MicMute/> : (isMicPressed ? <MicFill/> : <Mic/>)}
            </Button>
        </div>
        <div className="input-wrapper">
            <textarea 
                ref={refInput}
                onKeyDown={onKeyDown}
                placeholder={i18n.t('input_something')}
                disabled={gptLoading}
                onChange={onInputChange}
            />
            <Button 
                className="icon-btn" 
                onClick={onClickSendButton} 
                disabled={gptLoading}
            >
                <SendFill/>
            </Button>
        </div>
    </div>
}

export default InputBox;
