import React from 'react';
import classnames from 'classnames';
import Header from '../Header';
import SideBar from '../Sidebar';

import './style.scss';

const BasicTheme = props => {

    if (!props.header && !props.sidebar) {
        return props.children;
    }

    return <div className="theme-basic">
        {
            props.sidebar &&
            <div className={classnames("theme-basic-sidebar")}>
                <SideBar content={props.sidebarContent} />
            </div>
        }
        <div className="theme-basic-main">
            {
                props.header &&
                <div className="theme-basic-header">
                    <Header/>
                </div>
            }
            <div className={classnames("theme-basic-content")}>
                {props.children}
            </div>
        </div>
    </div>
}

export default BasicTheme;