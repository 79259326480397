import config from '../configs'
import {TTS_PROVIDER} from './constants'
import BaiduTTS from './tts.baidu'
// import XunfeiTTS from './tts.xunfei'

class TTS {
    constructor() {
        this.ttsInstance = null

        switch(config.tts_provider) {
            case TTS_PROVIDER.BAIDU:
                this.ttsInstance = BaiduTTS;
                break;

            // case TTS_PROVIDER.XUNFEI:
            //     this.ttsInstance = XunfeiTTS;
            //     break;

            default: 
                this.ttsInstance = BaiduTTS;
                break;
        }
    }
    start(text, callback) {
        const tts = new this.ttsInstance(text, callback)
        tts.start()
    }
}

export default new TTS();